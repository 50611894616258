<template>
	<div v-loading="loading">
		<div style="display:flex;justify-content: flex-end;width: 94%;margin: 0 auto;align-items: center;">
			<!-- <p style="font-size: 20px;">当前到校离校时间</p> -->
			<el-button type="primary" @click="dialogVisible = true">设置时间</el-button>
		</div>
		<el-table :data="data" empty-text="暂未设置到校离校时间,请点击右上角设置">
			<el-table-column prop="arrivestart" label="到校开始时间" align="center" width="185"></el-table-column>
			<el-table-column prop="arriveend" label="到校结束时间" align="center"></el-table-column>
			<el-table-column prop="leavestart" label="离校开始时间" align="center"></el-table-column>
			<el-table-column prop="leaveend" label="离校结束时间" align="center"></el-table-column>
			<el-table-column prop="updatetime" label="上次更新时间" align="center" width="200"></el-table-column>
		</el-table>
		<el-dialog title="修改到校离校时间" :visible.sync="dialogVisible" center width="500px" :close-on-click-modal="false">
			<div style="text-align: center;">
				到校时间：<el-time-select v-model="arrivestart" placeholder="开始时间"
					:picker-options="{start: '06:00',step: '00:10',end: '20:00'}" style="width: 120px;">
				</el-time-select>&nbsp;-&nbsp;<el-time-select v-model="arriveend" placeholder="结束时间"
					:picker-options="{start: '06:00',step: '00:10',end: '20:00'}" style="width: 120px;">
				</el-time-select>
			</div>
			<div style="text-align: center;margin-top: 20px;">
				离校时间：<el-time-select v-model="leavestart" placeholder="开始时间"
					:picker-options="{start: '06:00',step: '00:10',end: '20:00'}" style="width: 120px;">
				</el-time-select>&nbsp;-&nbsp;<el-time-select v-model="leaveend" placeholder="结束时间"
					:picker-options="{start: '06:00',step: '00:10',end: '20:00'}" style="width: 120px;">
				</el-time-select>
			</div>
			<span slot="footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="ok">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		setArriveLeaveTime,
		arriveLeaveTime
	} from '@/api/user.js'
	export default {
		data() {
			return {
				data: [], //设置的到校离校时间数据
				loading: false, //加载框
				dialogVisible: false, //设置时间弹框
				arrivestart: '', //到校开始时间
				arriveend: '', //到校结束时间
				leavestart: '', //离校开始时间
				leaveend: '' //离校结束时间
			}
		},
		created() {
			this.getSchoolTime(); //获取设置的到校离校时间
		},
		methods: {
			async ok() {
				if (!this.arrivestart || !this.arriveend || !this.leavestart || !this.leaveend) {
					this.$message.error("请完善到校离校时间");
				} else if (this.arrivestart >= this.arriveend || this.leavestart >= this.leaveend || this.arriveend >=
					this.leavestart) {
					this.$message.error("到校离校的结束时间要大于开始时间，并且离校时间要大于到校时间");
				} else {
					const data = { //请求数据        
						arrivestart: this.arrivestart.replace(/^0/, '').replace(/:/, ''),
						arriveend: this.arriveend.replace(/^0/, '').replace(/:/, ''),
						leavestart: this.leavestart.replace(/^0/, '').replace(/:/, ''),
						leaveend: this.leaveend.replace(/^0/, '').replace(/:/, '')
					};
					let res = await setArriveLeaveTime(data); //请求接口
					if (res.status.code == 1) {
						this.$message.success("设置成功");
						this.dialogVisible = false;
						this.getSchoolTime(); //获取设置的到校离校时间
					} else {
						this.$message.error(res.status.msg);
					}
				}
			},
			async getSchoolTime() { //获取设置的到校离校时间
				this.loading = true;
				this.data = [];
				let res = await arriveLeaveTime(); //请求接口
				this.data.push(res.data); //将数据放在表格中
				this.loading = false;
			}
		}
	}
</script>
